import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  AuditLog,
  fetchAuditLog,
  fetchEntityAuditLog
} from '../actions/auditLogs';
import { RootState } from '../index';

const auditLogAdapter = createEntityAdapter<AuditLog>({
  sortComparer: (a, b) => {
    return a.timestamp - b.timestamp;
  }
});

const auditLogSlice = createSlice({
  name: 'auditLog',
  initialState: auditLogAdapter.getInitialState({
    isLoading: false
  }),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAuditLog.fulfilled, (state, { payload }) => {
        auditLogAdapter.setAll(state, payload);
        state.isLoading = false;
      })
      .addCase(fetchAuditLog.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchAuditLog.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchEntityAuditLog.fulfilled, (state, { payload }) => {
        auditLogAdapter.upsertMany(state, payload);
        state.isLoading = false;
      })
      .addCase(fetchEntityAuditLog.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchEntityAuditLog.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const initialState = auditLogSlice.getInitialState();
export type AuditLogState = typeof initialState;

export default auditLogSlice.reducer;
export const auditLogSelectors = auditLogAdapter.getSelectors<RootState>(
  state => state.auditLogs
);
