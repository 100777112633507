import React from 'react';
import { RoughNotation } from 'react-rough-notation';
import { Link } from 'react-router-dom';
import { KeyStudioCard } from '../../../components/cards/KeyStudioCard/KeyStudioCard';
import { RenderIfSuperAdmin } from '../../../components/util/RenderIfSuperAdmin';
import { getKeystudioLink } from '../../../utils/citykeyId';
import { useAdminRole } from '../../../utils/roles/useAdminRole';

interface AdminRoleCardProps {
  /**
   * What is admin role?
   */
  adminRole: string;
  type?: string;
}

export const AdminRoleCard = ({
  adminRole,
  type = 'tilgangslisten'
}: AdminRoleCardProps) => {
  const role = useAdminRole(adminRole);

  return (
    <RenderIfSuperAdmin>
      <KeyStudioCard size='small' transparent>
        <h3>Adminrolle</h3>
        <p>
          Administratorer med rollen{' '}
          {role ? (
            <RoughNotation type='underline' show={true} color='#ff8274'>
              <Link to={getKeystudioLink(role.uri)}>{role.name}</Link>
            </RoughNotation>
          ) : (
            adminRole
          )}{' '}
          kan administrere {type}.
        </p>
      </KeyStudioCard>
    </RenderIfSuperAdmin>
  );
};
