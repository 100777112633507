import React from 'react';
import { OperationType } from '../../proto/shared_pb';
import { OperationContainer } from '../OperationContainer/OperationContainer';
import { OperationStatus } from '../OperationStatus/OperationStatus';
import { AuditCard } from '../cards/AuditCard/AuditCard';
import { LinkToControlpanelCard } from '../cards/ControlpanelCards/LinkToControlpanelCard';
import { OpeningHoursCard } from '../cards/OpeningHoursCard/OpeningHoursCard';
import { ImageCard } from '../image/ImageCard';
import { OperationMenu } from './Menu/OperationMenu';
import { MetersToExecute } from './MetersToExecute';
import { OperationBreadcrumbs } from './OperationBreadcrumbs';
import { useOperation } from './OperationWrapper';
import { QRCard } from './QRCard/QRCard';
import { About } from './about/About';
import { AgeLimitCard } from './agelimit/AgeLimitCard';
import { KeypediaOperationCard } from './keypedia/KeypediaOperationCard';
import { LinkedKeychains } from './linnkedKeychains/LinkedKeychains';
import { PvdCard } from './pvd/PvdCard';
import { OperationTerms } from './terms/OperationTerms';

export const Operation: React.FC = () => {
  const { subject, operation } = useOperation();
  const publicKeychainFactories =
    operation.keychainFactoriesGrantingAccessList.filter(
      keychain => keychain.type === 'public'
    );
  return (
    <OperationContainer>
      <OperationBreadcrumbs subject={subject} operation={operation} />
      <div className='visibility-status'>
        {operation.visibleForEveryone && '(Synlig for alle)'}
        <span className='tooltiptext'>
          Fordi en operasjonen har offentlige åpningstider er den synlig for
          alle brukere av appen.
        </span>
      </div>
      <OperationMenu operation={operation} subjectId={subject.id} />
      <div className='operation-status'>
        <h3>
          <OperationStatus
            type='dot'
            subjectId={subject.id}
            operationId={operation.id}
          />
          {operation.problemsList[0]?.title || 'Fungerer'}
        </h3>
        {operation.problemsList[0]?.text?.content || ''}
      </div>
      <div className='operation-cards'>
        <ImageCard
          images={operation.imagesList}
          subjectId={subject.id}
          operationId={operation.id}
        />
        {publicKeychainFactories.length > 0 && <OpeningHoursCard />}
        {operation.mustBeWithinMetersToExecute !== 0 && (
          <MetersToExecute
            mustBeWithinMetersToExecute={operation.mustBeWithinMetersToExecute}
          />
        )}
        <About about={operation.about} />
        <OperationTerms termIds={operation.termIds} />
        <AgeLimitCard ageLimit={operation.ageLimit} />
        {operation.proximityDeviceId && (
          <PvdCard proximityDeviceId={operation.proximityDeviceId} />
        )}
        <KeypediaOperationCard {...operation.wiki} />
        {operation.operationType === OperationType.EXECUTE && (
          <QRCard subjectId={subject.id} operationId={operation.id} />
        )}
        <AuditCard type='operation' citykeyId={operation.citykeyId} />
        <LinkToControlpanelCard type='operation' />
      </div>
      <LinkedKeychains
        keychainFactoryList={operation.keychainFactoriesGrantingAccessList}
      />
    </OperationContainer>
  );
};
