import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { PageInfoText } from '../../../../components/PageInfoText/PageInfoText';
import { listUsersWithRole } from '../../../../redux/actions/keychainFactoryDetails';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { rolesSelectors } from '../../../../redux/slice/roles';
import { getKeystudioLink } from '../../../../utils/citykeyId';
import { useKeychainFactoryDetails } from '../../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../../KeychainFactoryBreadcrumbs';

type MatchParams = {
  roleId: string;
};

export const OidcRoleDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { keychainFactory } = useKeychainFactoryDetails();
  const { roleId = '' } = useParams<keyof MatchParams>();
  if (keychainFactory.type !== 'oidc') {
    return <p>Støtter ikke tilgangslistetype {keychainFactory.type}</p>;
  }
  const role = keychainFactory.roleDefinitionsList.find(role => {
    return role.id === roleId;
  });
  const { entries = [] } = useAppSelector(
    state => rolesSelectors.selectById(state, roleId) || {}
  );

  useEffect(() => {
    if (role?.uri) {
      dispatch(listUsersWithRole(role.uri));
    }
  }, [role]);
  const isLoading = useAppSelector(state => state.ui.isLoading);
  return (
    <section className={isLoading ? 'loading' : ''}>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Konfigurere roller'
        subName={role?.name || roleId}
      />
      {role ? (
        isLoading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <PageInfoText>{role?.description}</PageInfoText>
            <h2>
              {entries.length ? 'Disse' : 'Ingen'} har tilgang til{' '}
              <b>{role?.name || roleId}</b>
            </h2>
            <ul>
              {entries.map(entry => {
                return (
                  <li key={entry.keychainUri}>
                    <Link to={getKeystudioLink(entry.keychainUri)}>
                      {entry.fullName}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Fragment>
        )
      ) : (
        <h1>Finner ikke rollen: {roleId}</h1>
      )}
    </section>
  );
};
