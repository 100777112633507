import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UiState {
  selectedAdminTool: string | null;
  sideMenuOpen: boolean;
  isLoading: boolean;
  traceId: string;
}

export const initialState: UiState = {
  selectedAdminTool: null,
  sideMenuOpen: false,
  isLoading: false,
  traceId: ''
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSelectedAdminTool: (state, action: PayloadAction<string>) => {
      state.selectedAdminTool = action.payload;
    },
    clearSelectedAdminTool: state => {
      state.selectedAdminTool = null;
    },
    setSideMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.sideMenuOpen = action.payload;
    },
    setTraceId: (state, action: PayloadAction<string>) => {
      state.traceId = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => action.type.endsWith('/pending'),
        state => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        action =>
          action.type.endsWith('/fulfilled') ||
          action.type.endsWith('/rejected'),
        state => {
          state.isLoading = false;
        }
      );
  }
});

export const {
  setSelectedAdminTool,
  clearSelectedAdminTool,
  setSideMenuOpen,
  setTraceId
} = uiSlice.actions;
export default uiSlice.reducer;
