import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './OverviewCard.scss';

interface OverviewCardProps {
  title: string;
  linkUri?: string;
  icon?: React.ReactNode;
  healthy?: boolean;
  size?: 'medium' | 'wide';
  children: React.ReactNode;
}

export const OverviewCard: React.FC<OverviewCardProps> = ({
  title,
  linkUri,
  icon,
  healthy = true,
  size = 'medium',
  children
}) => {
  const cardClasses = classNames(
    'overview-card',
    {
      'not-healthy': !healthy,
      'has-alert': alert
    },
    `overview-card--${size}`
  );

  const CardContent = () => (
    <div className={cardClasses}>
      <h3 className='title'>{title}</h3>
      {icon && <div className='card-icon'>{icon}</div>}
      <div className='card-content'>{children}</div>
    </div>
  );

  return linkUri ? (
    <Link to={linkUri} className='card-link'>
      <CardContent />
    </Link>
  ) : (
    <CardContent />
  );
};
