import React from 'react';
import classNames from 'classnames';
import { useAppSelector } from '../../../redux/hooks';
import './KeychainFactoryContainer.scss';

interface KeychainFactoryContainerProps {
  children: React.ReactNode;
}

export const KeychainFactoryContainer: React.FC<
  KeychainFactoryContainerProps
> = ({ children }) => {
  const isLoading = useAppSelector(state => state.ui.isLoading);
  const classes = classNames('keystudio-keychain-factory-container', {
    loading: isLoading
  });
  return <section className={classes}>{children}</section>;
};
