import React from 'react';
import { Operation } from '../../redux/slice/operations';
import { Subject } from '../../redux/slice/subjects';
import {
  Breadcrumbs,
  generateSubjectBreadcrumbs
} from '../Navigation/breadcrumbs';

interface Props {
  subject: Subject;
  operation: Operation;
}

export const OperationBreadcrumbs: React.FC<Props> = ({
  subject,
  operation
}) => {
  const breadcrumbs = [
    ...generateSubjectBreadcrumbs(subject),
    {
      name: operation.name
    }
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
