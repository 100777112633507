import { z } from 'zod';
import {
  FetchAuditLogRequest,
  FetchAuditLogResponse,
  FetchEntityAuditLogRequest
} from '../../proto/data_pb';
import { Timestamp, TimestampInterval } from '../../proto/shared_pb';
import {
  getDataClient,
  getGrpcMetadata,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';

const auditLogSchema = z.object({
  id: z.number(),
  actor: z.string(),
  resourceId: z.string(),
  eventType: z.string(),
  summary: z.string(),
  details: z.string(),
  timestamp: z.number(),
  auditLogSource: z.string()
});

export type AuditLog = z.infer<typeof auditLogSchema>;

export const FetchAuditLog = z.object({
  fromDate: z.date(),
  untilDate: z.date()
});
export type FetchAuditLog = z.infer<typeof FetchAuditLog>;
export const fetchAuditLog = createAppAsyncThunk<AuditLog[], FetchAuditLog>(
  'auditLogs/fetchAuditLog',
  async ({ fromDate, untilDate }, thunkAPI) => {
    try {
      const response = await getDataClient().fetchAuditLog(
        new FetchAuditLogRequest().setTimestampRangeFilter(
          new TimestampInterval()
            .setFrom(new Timestamp().setEpochMillis(fromDate.getTime()))
            .setTo(new Timestamp().setEpochMillis(untilDate.getTime()))
        ),
        await getGrpcMetadata()
      );
      return response.toObject().entriesList.map(convertToAuditLog);
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const fetchEntityAuditLog = createAppAsyncThunk<
  AuditLog[],
  { citykeyId: string }
>('auditLogs/fetchEntityAuditLog', async ({ citykeyId }, thunkAPI) => {
  try {
    const response = await getDataClient().fetchEntityAuditLog(
      new FetchEntityAuditLogRequest().setCitykeyId(citykeyId),
      await getGrpcMetadata()
    );
    return response.toObject().entriesList.map(convertToAuditLog);
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

const auditLogSourceMap = {
  [FetchAuditLogResponse.AuditLogEntry.Source.BACKEND]: 'BACKEND',
  [FetchAuditLogResponse.AuditLogEntry.Source.AUTH]: 'AUTH'
};
const convertToAuditLog = (
  log: FetchAuditLogResponse.AuditLogEntry.AsObject
): AuditLog => {
  return auditLogSchema.parse({
    id: log.id,
    actor: log.actor,
    resourceId: log.resourceId,
    eventType: log.eventType,
    summary: log.summary,
    details: log.details,
    timestamp: log.timestamp?.epochMillis,
    auditLogSource: auditLogSourceMap[log.auditLogSource] || 'UKJENT'
  });
};
