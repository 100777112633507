import { formatISO, isFuture } from 'date-fns';
import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EndDatePicker } from '../../../../components/DatePickers/EndDatePicker';
import { StartDatePicker } from '../../../../components/DatePickers/StartDatePicker';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { extendKeychainExpireDateRequest } from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Keychain } from '../../../../redux/slice/keychains';
import { Shareable } from '../grantAccess/Shareable';

interface Props {
  DialogBox: DialogBoxType;
  keychain?: Keychain;
  keychainFactoryUri: string;
  closeModal: () => void;
}

export const EditApprovedKeychains: React.FC<Props> = ({
  keychain,
  closeModal,
  DialogBox,
  keychainFactoryUri
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<{
    startDate: Date;
    endDate: Date;
    shareable: 'true' | 'false';
  }>({
    defaultValues: {
      startDate: keychain?.fromDate ? new Date(keychain?.fromDate) : new Date(),
      endDate: keychain?.untilDate ? new Date(keychain?.untilDate) : new Date(),
      shareable: keychain?.shareable ? 'true' : 'false'
    }
  });

  useEffect(() => {
    methods.reset({
      startDate: keychain?.fromDate ? new Date(keychain?.fromDate) : new Date(),
      endDate: keychain?.untilDate ? new Date(keychain?.untilDate) : new Date(),
      shareable: keychain?.shareable ? 'true' : 'false'
    });
  }, [keychain, methods]);

  const { startDate, endDate, shareable } = methods.watch();

  const onUpdate = useMemo(() => {
    if (!keychain) {
      return () => {};
    }
    return () =>
      dispatchAndHandleResult(
        dispatch,
        extendKeychainExpireDateRequest,
        {
          keychainId: keychain.id,
          newExpireDate: formatISO(new Date(endDate), {
            representation: 'date'
          }),
          keychainFactoryUri,
          shareable: shareable === 'true'
        },
        closeModal
      );
  }, [closeModal, endDate, keychain, keychainFactoryUri, shareable]);

  return (
    <DialogBox
      buttonLabel='Oppdater'
      onSubmit={onUpdate}
      methods={methods}
      header={keychain?.personName}
    >
      <div>Tilgang er gyldig i perioden:</div>
      <div className='grid-container'>
        <StartDatePicker
          name='startDate'
          startDate={startDate}
          endDate={endDate}
          disabled={true}
        />
        <EndDatePicker
          name='endDate'
          startDate={startDate}
          endDate={endDate}
          position='right'
        />
        {keychain?.policies.setShareable &&
          (keychain.status === 'APPROVED' || isFuture(endDate)) && (
            <Shareable />
          )}
      </div>
    </DialogBox>
  );
};
