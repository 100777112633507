import React, { useEffect, useState } from 'react';
import { ButtonProps } from '../../../../components/Button/Button';
import { useDialogBox } from '../../../../components/DialogBox/useDialogBox';
import { TableWithButtons } from '../../../../components/Table/TableWithButtons';
import { getKeychainDetails } from '../../../../redux/actions/keychains';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { KeychainFactoryDetails } from '../../../../redux/slice/keychainFactoryDetails';
import { keychainsSelectors } from '../../../../redux/slice/keychains';
import { GrantAccess } from '../grantAccess/grantAccess';
import { GrantOidcAccess } from '../grantAccess/grantOidcAccess';
import './ApprovedKeychains.scss';
import { EditAllApprovedKeychains } from './EditAllApprovedKeychains';
import { KeychainDialogs } from './KeychainDialogs';
import { keychainDataGenerator } from './keychainDataGenerator';

interface Props {
  keychainFactory: KeychainFactoryDetails;
}

export const ApprovedKeychains: React.FC<Props> = ({ keychainFactory }) => {
  const { keychainFactoryUri } = keychainFactory;
  const [GrantAccessDialog, openGrantAccess, closeGrantAccess] = useDialogBox();
  const [EditAllDialog, openEditAll, closeEditAll] = useDialogBox();
  const [DeleteDialog, openDelete, closeDelete] = useDialogBox();
  const [EditDialog, openEdit, closeEdit] = useDialogBox();
  const [EmailDialog, openEmail, closeEmail] = useDialogBox();
  const [RolesDialog, openRoles, closeRoles] = useDialogBox();
  const [activeKeychainId, setActiveKeychainId] = React.useState('');
  const [expiredInsteadOfDelete, setExpiredInsteadOfDelete] = useState(false);
  const dispatch = useAppDispatch();
  const isOidcFactory = keychainFactory.type === 'oidc';

  const keychain = useAppSelector(state =>
    keychainsSelectors.selectById(state, activeKeychainId)
  );
  useEffect(() => {
    if (activeKeychainId) {
      dispatch(getKeychainDetails(activeKeychainId));
    }
  }, [activeKeychainId]);

  const tableData = keychainDataGenerator(
    setActiveKeychainId,
    setExpiredInsteadOfDelete,
    openEdit,
    openDelete,
    openEmail,
    openRoles
  );

  const tableButtons: ButtonProps[] =
    keychainFactory.type === 'push'
      ? []
      : [
          {
            type: 'secondary',
            label: 'Forny tilgang til alle',
            onClick: openEditAll
          },
          {
            type: 'primary',
            label: 'Legg til bruker',
            onClick: openGrantAccess
          }
        ];

  return (
    <div className='manage-access'>
      <KeychainDialogs
        keychain={keychain}
        keychainFactoryUri={keychainFactoryUri}
        DeleteDialog={DeleteDialog}
        closeDelete={closeDelete}
        EditDialog={EditDialog}
        closeEdit={closeEdit}
        EmailDialog={EmailDialog}
        closeEmail={closeEmail}
        RolesDialog={RolesDialog}
        closeRoles={closeRoles}
        expiredInsteadOfDelete={expiredInsteadOfDelete}
      />
      <TableWithButtons
        buttons={tableButtons}
        table={tableData}
        ting='bruker'
        appendText='på tilgangslisten'
      />
      {isOidcFactory ? (
        <GrantOidcAccess
          keychainFactory={keychainFactory}
          DialogBox={GrantAccessDialog}
          closeModal={closeGrantAccess}
        />
      ) : (
        <GrantAccess
          keychainFactoryUri={keychainFactoryUri}
          DialogBox={GrantAccessDialog}
          closeModal={closeGrantAccess}
        />
      )}
      <EditAllApprovedKeychains
        DialogBox={EditAllDialog}
        keychainFactoryUri={keychainFactoryUri}
        closeModal={closeEditAll}
      />
    </div>
  );
};
