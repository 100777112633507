import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { deleteKeychainExpireDateRequest } from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Keychain } from '../../../../redux/slice/keychains';
import { getKeystudioLink } from '../../../../utils/citykeyId';

interface Props {
  keychain?: Keychain;
  keychainFactoryUri: string;
  DialogBox: DialogBoxType;
  closeModal: () => void;
  expiredInsteadOfDelete: boolean;
}

export const DeleteApprovedKeychains: React.FC<Props> = ({
  keychain,
  DialogBox,
  closeModal,
  keychainFactoryUri,
  expiredInsteadOfDelete
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const removeUserClick = useMemo(() => {
    if (!keychain) {
      return {
        onClick: () => {}
      };
    }
    return {
      label: expiredInsteadOfDelete ? 'Deaktiver bruker' : 'Slett bruker',
      onClick: () =>
        dispatchAndHandleResult(
          dispatch,
          deleteKeychainExpireDateRequest,
          {
            keychainId: keychain.id,
            keychainFactoryUri,
            expiredInsteadOfDelete
          },
          () => {
            closeModal();
            if (!expiredInsteadOfDelete) {
              navigate(
                getKeystudioLink(keychainFactoryUri) + '/tilgangskontroll'
              );
            }
          }
        )
    };
  }, [closeModal, dispatch, keychain, keychainFactoryUri]);

  return (
    <DialogBox
      buttonLabel={removeUserClick?.label}
      buttonType='destructive'
      onSubmit={removeUserClick.onClick}
      header={keychain?.personName}
    >
      Ønsker du å {expiredInsteadOfDelete ? 'deaktivere' : 'slette'} brukeren?
    </DialogBox>
  );
};
