import {
  manualGrantKeychainHome,
  oidcKeychainHome,
  publicKeychainHome,
  pushKeychainHome
} from '../components/Navigation/navPaths';

export type Domain =
  | 'factories'
  | 'third-party-systems'
  | 'group-links'
  | 'categories'
  | 'subjects'
  | 'admission-cards'
  | 'operation-buckets'
  | 'chains'
  | 'terms'
  | 'webhooks';

type FactoryType = {
  entity: 'factory';
  factoryType: string;
  factoryId: string;
};
type KeychainType = {
  entity: 'keychain';
  factoryType: string;
  factoryId: string;
  keychainId: string;
};
type RoleType = {
  entity: 'role';
  factoryType: string;
  factoryId: string;
  roleId: string;
};
type SubjectType = {
  entity: 'subject';
  subjectId: string;
};
type UnkonwnType = {
  entity: 'unknown';
};

type CitykeyData =
  | FactoryType
  | KeychainType
  | RoleType
  | SubjectType
  | UnkonwnType;

export const getKeystudioLink = (citykeyUri = '') => {
  const parsedType = getDataFromCitykeyUri(citykeyUri);
  let link = '';
  if ('factoryType' in parsedType) {
    link = getFactoryPath(parsedType.factoryType, parsedType.factoryId);
    if (parsedType.entity === 'keychain') {
      link += '/tilgangskontroll/' + parsedType.keychainId;
    }
    if (parsedType.entity === 'role') {
      link += '/roller/' + parsedType.roleId;
    }
  } else if (parsedType.entity === 'subject') {
    link = `/steder/${parsedType.subjectId}`;
  }
  return link;
};

const getDataFromCitykeyUri = (citykeyUri: string): CitykeyData => {
  const sliced = citykeyUri.split('/');
  let data;
  switch (sliced[2] as Domain) {
    case 'factories':
      data = {
        factoryType: sliced[3],
        factoryId: sliced[4]
      };
      switch (sliced[5]) {
        default:
          return {
            ...data,
            entity: 'factory'
          };
        case 'keychains':
          return {
            ...data,
            entity: 'keychain',
            keychainId: sliced[6]
          };
        case 'roles':
          return {
            ...data,
            entity: 'role',
            roleId: sliced[6]
          };
      }
    case 'subjects':
      return {
        entity: 'subject',
        subjectId: sliced[3]
      };
  }
  return {
    entity: 'unknown'
  };
};

const getFactoryPath = (citykeyIdFactoryType: string, factoryId: string) => {
  switch (citykeyIdFactoryType) {
    case 'manual-code':
      return `${manualGrantKeychainHome.path}/${factoryId}`;
    case 'push':
      return `${pushKeychainHome.path}/${factoryId}`;
    case 'oidc':
      return `${oidcKeychainHome.path}/${factoryId}`;
    case 'public':
      return `${publicKeychainHome.path}/${factoryId}`;
  }
  throw 'FEIL';
};

export const getKeystudioUri = (citykeyUri: string) => {
  const data = getDataFromCitykeyUri(citykeyUri);
  if ('factoryType' in data) {
    return `citykey://factories/${data.factoryType}/${data.factoryId}`;
  }
  throw new Error(
    "Ikke mulig å utlede keystudio uri fra denne citykey uri'en: " + citykeyUri
  );
};
