import React, { useCallback } from 'react';
import { updateOperationName } from '../../../redux/actions/operations';
import { useAppDispatch } from '../../../redux/hooks';
import { Operation } from '../../../redux/slice/operations';
import { ActionMenu } from '../../ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../../ActionMenu/ActionMenuItem';
import { RenameDialog } from '../../Dialog/RenameDialog';

type Props = {
  operation: Operation;
  subjectId: string;
};
export const OperationMenu: React.FC<Props> = ({ operation, subjectId }) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const renameAction = useCallback(
    (name: string) => {
      dispatch(
        updateOperationName({
          subjectId: subjectId,
          operationId: operation.id,
          name
        })
      );
    },
    [operation.id]
  );

  const actions: ActionMenuItemProps[] = [
    {
      type: 'edit',
      description: 'Endre navn på operasjon',
      onClick: () => setIsRenameModalOpen(true)
    }
  ];

  return (
    <div className='actionmenu-container'>
      <ActionMenu actions={actions} />
      <RenameDialog
        isModalOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        initialName={operation.name}
        storeAction={renameAction}
        formLabel='Nytt navn på operasjonen'
      />
    </div>
  );
};
