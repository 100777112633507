import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import classNames from 'classnames';
import { useAppSelector } from '../../redux/hooks';
import { Button, ButtonProps } from '../Button/Button';
import './DialogFormBox.scss';

export type DialogBoxProps<T extends FieldValues> = {
  methods?: ReturnType<typeof useForm<T>>;
  buttonLabel?: string;
  buttonType?: ButtonProps['type'];
  onSubmit: SubmitHandler<T>;
  isModalOpen: boolean;
  closeModal: () => void;
  header?: string;
};

export const DialogFormBox = <T extends FieldValues>({
  children,
  methods = useForm(),
  buttonLabel = 'Lagre',
  buttonType = 'submit',
  onSubmit,
  header,
  isModalOpen,
  closeModal
}: PropsWithChildren<DialogBoxProps<T>>) => {
  const isLoading = useAppSelector(state => state.ui.isLoading);
  const buttons: ButtonProps[] = [
    {
      type: 'secondary',
      size: 'dialog',
      label: 'Avbryt',
      onClick: closeModal
    },
    {
      type: buttonType,
      size: 'dialog',
      label: buttonLabel
    }
  ];

  const buttonsJsx = buttons.map(buttonProps => {
    buttonProps.size = 'dialog';
    return <Button key={buttonProps.label} {...buttonProps} />;
  });
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isModalOpen) {
      if (!modalRef.current?.open) {
        modalRef.current?.showModal();
      }
    } else {
      if (modalRef.current?.open) {
        modalRef.current.close();
      }
    }
  }, [isModalOpen]);
  const classes = classNames('keystudio-box-outline', { loading: isLoading });
  return createPortal(
    <dialog className={classes} ref={modalRef} onClose={closeModal}>
      <FormProvider {...methods}>
        <form
          className='keystudio-modal-content'
          onSubmit={methods.handleSubmit(onSubmit, e => {
            console.warn(e);
          })}
        >
          <header className='top'>
            <span>{header}</span>
            <span className='close' onClick={closeModal}>
              ✕
            </span>
          </header>
          <div className='main'> {children}</div>
          <footer className='button-panel'>{buttonsJsx}</footer>
        </form>
      </FormProvider>
    </dialog>,
    document.body
  );
};
