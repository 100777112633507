import { formatDistanceToNow } from 'date-fns';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FactoriesSvg, SubjectsSvg } from '../../assets/navbar';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import {
  keychainHome,
  subjectHome
} from '../../components/Navigation/navPaths';
import { OverviewCard } from '../../components/cards/OverviewCard/OverviewCard';
import { fetchListKeychains } from '../../redux/actions/keychains';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectKeychainFactories } from '../../redux/slice/keychainFactorySummaries';
import { keychainsSelectors } from '../../redux/slice/keychains';
import { subjectSelectors } from '../../redux/slice/subjects';
import { getKeystudioLink } from '../../utils/citykeyId';
import { getNameFromEmail } from '../../utils/userProfile';
import './OverviewPage.scss';

export const OverviewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const keychainFactories = useAppSelector(selectKeychainFactories.selectAll);
  const user = useAppSelector(state => state.user);
  const anomalyMessages = subjects.filter(subject => {
    if (subject.anomalyMessage.timestampMillis) {
      return subject;
    }
  });

  const keychains = useAppSelector(keychainsSelectors.selectAll);

  useEffect(() => {
    if (keychainFactories.length > 0 && keychains.length === 0) {
      let keychainFactory = keychainFactories.find(keychainFactory => {
        return (
          keychainFactory.keychainFactoryUri ===
          'citykey://factories/manual-code/105692'
        );
      });
      if (!keychainFactory) {
        keychainFactory = keychainFactories.find(keychainFactory => {
          return keychainFactory.type === 'oidc';
        });
      }
      if (keychainFactory) {
        dispatch(fetchListKeychains(keychainFactory.keychainFactoryUri));
      }
    }
  }, [keychains, keychainFactories.length]);

  // const expiringKeychains = keychains.filter(keychain => {
  //   const daysLeft = calculateDaysBetween(
  //     new Date(keychain.untilDate),
  //     new Date()
  //   );
  //   return daysLeft < 15;
  // });
  //
  // const inactiveKeychains = keychains.filter(keychain => {
  //   const lastUsed = calculateDaysBetween(
  //     new Date(),
  //     new Date(keychain.lastUsedAtEpochSeconds * 1000)
  //   );
  //   return lastUsed > 150;
  // });

  return (
    <section className='overview'>
      <Breadcrumbs breadcrumbs={[{ name: 'Velkommen', path: '/' }]} />
      <h2>Hei {getNameFromEmail(user.username)}!</h2>
      <p>
        Her er en oversikt over hva du kan administrere i Oslonøkkelen.
        Informasjonen nedenfor representerer din bruk av Oslonøkkelen i dag.
      </p>
      <br />
      <div className='cards'>
        <OverviewCard
          title='Steder'
          linkUri={subjectHome.path}
          icon={<SubjectsSvg />}
        >
          <span>Du administerer {subjects.length} steder</span>
        </OverviewCard>

        <OverviewCard
          title='Tilgangslister'
          linkUri={keychainHome.path}
          icon={<FactoriesSvg />}
        >
          <span>Du administerer {keychainFactories.length} tilgangslister</span>
        </OverviewCard>
        <OverviewCard
          title='Aktive avviksmeldinger'
          healthy={anomalyMessages.length === 0}
          size='wide'
        >
          <span>
            {anomalyMessages.length === 0 && (
              <span className='dim'>
                Hurra! Du har ingen aktive avviksmeldinger!
              </span>
            )}
            {anomalyMessages.map(subject => (
              <Link
                key={subject.id}
                to={getKeystudioLink(subject.citykeyId) + '/avvik'}
              >
                <br />
                {subject.name} -{' '}
                {formatDistanceToNow(subject.anomalyMessage.timestampMillis, {
                  addSuffix: false,
                  includeSeconds: false
                })}
              </Link>
            ))}
          </span>
        </OverviewCard>
        {/*<OverviewCard title='Utløpende tilganger' size='wide'>*/}
        {/*  {getRandomSelection(expiringKeychains, 5).map(keychain => {*/}
        {/*    return (*/}
        {/*      <Link key={keychain.id} to={getKeystudioLink(keychain.id)}>*/}
        {/*        <br />*/}
        {/*        {keychain.personName} -{' '}*/}
        {/*        {generateStatusText(*/}
        {/*          keychain.status,*/}
        {/*          keychain.fromDate,*/}
        {/*          keychain.untilDate*/}
        {/*        )}*/}
        {/*      </Link>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*  {expiringKeychains.length === 0 && (*/}
        {/*    <span className='dim'>Hurra! Du har ingen utløpende tilganger</span>*/}
        {/*  )}*/}
        {/*</OverviewCard>*/}
        {/*<OverviewCard title='Inaktive tilganger' size='wide'>*/}
        {/*  {getRandomSelection(inactiveKeychains, 5).map(keychain => {*/}
        {/*    return (*/}
        {/*      <Link key={keychain.id} to={getKeystudioLink(keychain.id)}>*/}
        {/*        <br />*/}
        {/*        {keychain.personName} -{' '}*/}
        {/*        {generateLastUsedText(keychain.lastUsedAtEpochSeconds)}*/}
        {/*      </Link>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*  {inactiveKeychains.length === 0 && (*/}
        {/*    <span className='dim'>Hurra! Du har ingen inaktive tilganger</span>*/}
        {/*  )}*/}
        {/*</OverviewCard>*/}
      </div>
    </section>
  );
};

// const getRandomSelection = <T,>(arr: T[], num: number) => {
//   if (arr.length <= num) {
//     return arr;
//   }
//
//   const shuffled = [...arr];
//
//   for (let i = shuffled.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
//   }
//   return shuffled.slice(0, num);
// };
